import React from 'react'

import StatusBar from '../TaskBar/TaskBar'
import NewTask from '../NewTask/NewTask'
import AllTasks from '../AllTasks/AllTasks'

import { useSelector } from 'react-redux'
import { AppStateType } from '../../redux/rootReducer'

import style from './contentWrapper.module.scss'

const statuses = {
  noTasks: '',
  currentTask: 'Текущая задача',
  allTasks: 'Все задачи'
}

const roles = {
  newTasks: ['Швея', 'Сборщик', 'Окрасчик', 'Поролонщик', 'Обивщик', 'ОТК', 'Резчик'],
  allTasks: ['Закройщик', 'Распиловщик']
}

const ContentWrapper: React.FC = () => {
  const userRole = useSelector((state: AppStateType) => state.auth.role)
  const activeTasks = useSelector((state: AppStateType) => state?.tasks?.activeTasks)

  // Выбираем нужную роль для отображения задач
  const findRole = (arr: Array<string>, role: string) => {
    return arr.findIndex(item => {
      return item === role
    })
  }

  const statusTasks = activeTasks?.length === 0 ? statuses.noTasks
    : findRole(roles.newTasks, userRole) >= 0 ? statuses.currentTask
      : findRole(roles.allTasks, userRole) >= 0 ? statuses.allTasks
        : ''

  if (!userRole) return null

  return (
    <div className={style.contentWrapper}>
      <StatusBar status={statusTasks}/>

      {
        (findRole(roles.newTasks, userRole) >= 0) && <NewTask />
      }
      {
        (findRole(roles.allTasks, userRole) >= 0) && <AllTasks />
      }
    </div>
  )
}

export default ContentWrapper